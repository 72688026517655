<template>
  <div class="youtube-wrapper">
    <iframe :src="src" frameborder="0" allowfullscreen></iframe>
  </div>
</template>

<script>

export default {
  name: 'YoutubeEmbed',
  props: ['videoId'],
  computed: {
    src () {
      return `https://www.youtube.com/embed/${this.videoId}`
    }
  }
}
</script>

<style lang="scss" scoped>
.youtube-wrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 700px;

  iframe {
    width: 100%;
    height: 177px;

    @include tablet {
      height: 390px;
    }

    @include desktop {
      height: 376px;
    }

    @include desktop-lg {
      height: 376px;
    }
  }
}
</style>
