<template>
  <div>
    <template v-if="loader">
      <Loader
        :size="'big'"
      />
    </template>
    <template v-else-if="entry">
      <!-- COVER -->
      <div class="header mb-4" :style="`background-image: url(${coverImageUrl});`"></div> <!-- END COVER -->
      <!-- CONTENT -->
      <div>
        <h1 class="font-size-medium text-center mb-4">{{ entry.fields.title }}</h1>
        <div class="my-4" v-if="entry.fields.youtubeVideoId">
          <YoutubeEmbed
            :videoId="entry.fields.youtubeVideoId"
          />
        </div>
        <div class="my-4" v-if="entry.fields.content">
          <div v-html="html"></div>
        </div>
        <div class="mt-4 text-center">
          <router-link :to="{ name: 'help-center.index' }" class="btn btn-primary">
            {{ $t('back') }}
          </router-link>
        </div>
      </div><!-- END CONTENT -->
    </template>
  </div>
</template>

<script>

import HelpCenterServices from '@/modules/help-center/services/help-center-services'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import Loader from '@/components/common/Loader/Loader'
import YoutubeEmbed from '@/components/common/Video/YoutubeEmbed'

export default {
  components: {
    Loader,
    YoutubeEmbed
  },
  data () {
    return {
      entry: null,
      html: null,
      loader: false
    }
  },
  created () {
    this.getEntry()
  },
  watch: {
    $route (to, from) {
      this.reset()
      this.getEntry()
    }
  },
  computed: {
    /**
     * Cover image url
     */
    coverImageUrl () {
      return this.entry.fields.cover.fields.file.url
    }
  },
  methods: {
    /**
     * Get entry
     */
    async getEntry () {
      this.loader = true
      try {
        const entries = await HelpCenterServices.getEntryBySlug(this.$route.params.slug)
        if (entries.items.length === 0) this.$router.push({ name: 'help-center.index' })
        // Get associated assets (cover and thumbnail)
        entries.items.forEach(entry => {
          if (entry.fields.cover) {
            entry.fields.cover = entries.includes.Asset.find(item => {
              return item.sys.id === entry.fields.cover.sys.id
            })
          }
          if (entry.fields.thumbnail) {
            entry.fields.thumbnail = entries.includes.Asset.find(item => {
              return item.sys.id === entry.fields.thumbnail.sys.id
            })
          }
        })
        this.entry = entries.items[0]
        this.parseEntryContent(this.entry)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    async parseEntryContent (entry) {
      const html = await documentToHtmlString(entry.fields.content, {
        renderNode: {
          [BLOCKS.EMBEDDED_ASSET]: (node) => `
            <div class="text-center mb-2">
            <img
              src='https://${node.data.target.fields.file.url}'
              alt='${node.data.target.fields.description}'
            /></div>`
        }
      })
      this.html = html
    },
    /**
     * Reset page
     */
    reset () {
      this.entry = null
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  -webkit-box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.15);
  border: none;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
}
</style>
